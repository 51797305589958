import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import favicon from "../../static/favicon.png";

function Seo({ description, meta, Sitetitle }) {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
					image
					siteUrl
				}
			}
		}
	`);

	const metaDescription = description || site.siteMetadata.description;
	const imageSE0 = site.siteMetadata.image;

	return (
		<Helmet
			htmlAttributes={{
				lang: "en",
			}}
			title={`${Sitetitle} | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: `${site.siteMetadata.siteUrl}`,
				},
				{
					property: `og:title`,
					content: `${Sitetitle} | ${site.siteMetadata.title}`,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					name: `og:image`,
					content: `${site.siteMetadata.siteUrl}${imageSE0}`,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					property: `twitter:url`,
					content: `${site.siteMetadata.siteUrl}`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: `${Sitetitle} | ${site.siteMetadata.title}`,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:image`,
					content: `${site.siteMetadata.siteUrl}${imageSE0}`,
				},
			].concat(meta)}
		>
			<link rel="icon" href={favicon} />
		</Helmet>
	);
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	Sitetitle: PropTypes.string.isRequired,
};

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

export default Seo;
